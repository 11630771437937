var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { api } from "../client";
var OneAttempt = require("@/assets/images/content/one-attempt.png");
var QuietSpace = require("@/assets/images/content/quiet-space.png");
var SaveData = require("@/assets/images/content/save-your-mobile.png");
export function getStepInformation() {
    return [
        {
            heading: "One attempt",
            description: "Please remember you only have one attempt at finishing this application step.",
            image: OneAttempt,
        },
        {
            heading: "Save your mobile data",
            description: "Make sure to be in an area with free Wifi as the process is relatively data intense.",
            image: SaveData,
        },
        {
            heading: "Quiet space to concentrate",
            description: "Ideally you are in a relatively quiet space so you can concentrate and give it your best.",
            image: QuietSpace,
        },
    ];
}
export function sendEmailRecommendation(emails) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.post("/api/v1/recommendations", { emails: emails })];
                case 1:
                    _a.sent();
                    return [2 /*return*/, {}];
            }
        });
    });
}
export function sendApplicationTest(_a) {
    var applicationTestId = _a.applicationTestId, operation = _a.operation, payload = _a.payload;
    return __awaiter(this, void 0, void 0, function () {
        var pageNumber, restPayload, data;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    pageNumber = payload.pageNumber, restPayload = __rest(payload, ["pageNumber"]);
                    return [4 /*yield*/, api.patch("/api/v1/application-tests/" + applicationTestId, {
                            application_test_id: applicationTestId,
                            payload: __assign(__assign({}, restPayload), { page_number: pageNumber }),
                            operation: operation,
                        })];
                case 1:
                    data = (_b.sent()).data;
                    return [2 /*return*/, convertApplication(data)];
            }
        });
    });
}
export function createApplicationTest(params) {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.post("/api/v1/application-tests", {
                        application_step_id: params.applicationStepId,
                    })];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, convertApplication(data)];
            }
        });
    });
}
export function getApplicationTest(params) {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.get("/api/v1/application-tests/" + params.ongoingApplicationTestId)];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, convertApplication(data)];
            }
        });
    });
}
export function cancelApplicationTest(_a) {
    var applicationTestId = _a.applicationTestId;
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, api.post("/api/v1/application-tests/" + applicationTestId + "/cancel")];
                case 1:
                    data = (_b.sent()).data;
                    return [2 /*return*/, convertApplication(data)];
            }
        });
    });
}
export function cancelApplication(_a) {
    var applicationId = _a.applicationId, cancellationReason = _a.cancellationReason, cancellationExplanation = _a.cancellationExplanation;
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, api.post("/api/v1/applications/" + applicationId + "/cancel", {
                        cancellation_reason: cancellationReason,
                        cancellation_explanation: cancellationExplanation,
                    })];
                case 1:
                    data = (_b.sent()).data;
                    return [2 /*return*/, convertOrganizationApplication(data)];
            }
        });
    });
}
export var convertApplication = function (data) {
    return __assign(__assign({}, data), { endIn: data.end_in, endAt: data.end_at, numberOfPages: data.number_of_pages, currentPage: {
            title: data.current_page.title,
            pageNumber: data.current_page.page_number,
            numberOfQuestions: data.current_page.number_of_questions,
            questions: data.current_page.questions.map(function (question) { return ({
                id: question.id,
                question: question.question,
                type: question.type,
                optional: question.optional,
                options: question.options,
                questionImageUrl: question.question_image_url,
                uploadedAnswerSignedIds: question.uploaded_answer_signed_ids,
                uploadedAnswerUrls: question.uploaded_answer_urls,
                endAt: question.end_at,
                answer: question.answer,
                chosenOptionId: question.chosen_option_id,
                chosenOptionIds: question.chosen_option_ids,
                validationRules: question.validation_rules.map(function (rule) { return ({
                    name: rule.name,
                    value: rule.value,
                }); }),
            }); }),
        } });
};
export function getOrganizationApplications(params) {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.get("/api/v1/organizations/" + params.organizationId + "/applications")];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.map(function (item) { return convertOrganizationApplication(item); })];
            }
        });
    });
}
export function getOrganizationApplication(params) {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.get("/api/v1/applications/" + params.id)];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, convertOrganizationApplication(data)];
            }
        });
    });
}
export function submitOrganizationApplication(params) {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.post("/api/v1/applications/" + params.id + "/submit")];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, convertOrganizationApplication(data)];
            }
        });
    });
}
export function createApplication(params) {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.post("/api/v1/organizations/" + params.organizationId + "/applications", { portfolio_id: params.portfolioId })];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, convertOrganizationApplication(data)];
            }
        });
    });
}
export var convertOrganizationApplication = function (data) {
    return {
        id: data.id,
        portfolioId: data.portfolio_id,
        createdAt: data.created_at,
        updatedAt: data.updated_at,
        cancelledAt: data.cancelled_at,
        submittedAt: data.submitted_at,
        numberOfFounders: data.number_of_founders,
        status: data.status,
        investmentExpired: data.investment_expired,
        investmentCancelled: data.investment_cancelled,
        portfolioOwner: data.portfolio_owner,
        portfolioVideoUrl: data.portfolio_video_url,
        portfolioTypeformReference: data.portfolio_typeform_reference,
        applicationPageConfigurations: data.application_page_configurations.map(function (page) { return ({
            id: page.id,
            order: page.order,
            title: page.title,
            completionMessage: page.completion_message,
            applicationSteps: page.application_steps.map(function (step) { return ({
                id: step.id,
                enabled: step.enabled,
                order: step.order,
                name: step.name,
                description: step.description,
                isCancelled: step.is_cancelled,
                timeLimit: step.time_limit,
                timeEstimate: step.time_estimate,
                videoUrl: step.video_url,
                scope: step.scope,
                maxNumberOfAttempts: step.max_number_of_attempts,
                leftAttempts: step.left_attempts,
                submittedAt: step.submitted_at,
                numberOfCofounderSubmissions: step.number_of_cofounder_submissions,
                ongoingApplicationTestId: step.ongoing_application_test_id,
            }); }),
        }); }),
    };
};
